

/* Global Styles */
body {
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
}

/* Sidebar */
.sidebar {
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  background-color: #343a40;
  overflow-x: hidden;
  transition: width 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  position: fixed;
  top: 56px;
  left: 0;
  z-index: 999;
  padding-top: 20px;
}

.sidebar .nav-link {
  color: #ffffff;
  font-weight: bold;
  padding: 8px 20px;
  text-align: left;
  font-size: 0.9rem;
  border-radius: 5px;
  transition: background-color 0.2s ease;
}

.sidebar .nav-link:hover {
  background-color: #495057;
  color: #f8f9fa;
}

.btn-logout {
  width: 80%;
  margin-left: 10%;
  border-radius: 5px;
  padding: 8px 0;
  font-size: 0.9rem;
  position: absolute;
  bottom: 20px;
  left: 0;
}

/* Content */
.content {
  transition: margin-left 0.3s ease;
  min-height: 100vh;
  overflow-x: auto;
  margin-left: 220px;
  padding-top: 70px;
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    position: absolute;
  }
  .content {
    margin-left: 0;
    width: 100%;
  }
}

/* Header */
.header {
  background-color: #343a40;
  color: white;
  padding: 10px 20px;
  font-size: 1.25rem;
  font-weight: bold;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1050;
  display: flex;
  align-items: center;
  height: 56px;
}

/* Buttons */
.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #004085;
}

.btn-danger {
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
}

button.btn.btn-sm.btn-light {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-top: auto;
  margin-bottom: auto;
}

/* Cards */
.card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  width: 30%;
  margin: 20px 0;
}

.card h3 {
  margin-bottom: 10px;
  font-size: 18px;
}

.card p {
  font-size: 24px;
  font-weight: bold;
}

/* Forms */
.form-control {
  border-radius: 5px;
}

/* Images */
img {
  max-width: 100%;
  height: auto;
}

/* Lists */
.list-group-item {
  background-color: #ffffff;
}

.list-group-item:hover {
  background-color: #e9ecef;
}

/* Dashboard Overview */
.dashboard-section {
  margin-bottom: 20px;
}

.dashboard-section h3 {
  margin-bottom: 10px;
}

.dashboard-section ul {
  list-style: none;
  padding: 0;
}

.dashboard-section li {
  background-color: #f8f9fa;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
}

.dashboard-chart {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Modal */
.modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  z-index: 1050;
  margin: 0;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.modal-content {
  padding: 20px;
  border-radius: 10px;
  width: auto;
  margin: auto;
}

.modal-header {
  border-bottom: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header .close {
  font-size: 1.5rem;
  background: none;
  border: none;
  padding: 0;
  margin-left: auto;
  cursor: pointer;
}

.modal-footer .btn {
  margin: 0 10px; /* Margine tra i pulsanti */
}

.modal-backdrop.show {
  opacity: 0.5;
}

@media (max-width: 768px) {
  .modal-dialog {
    width: 90%;
    margin: 0;
  }

  .modal-content {
    width: 100%;
    margin: 10px;
  }

  .btn {
    display: block;
    width: 100%;
    margin-bottom: 10px; /* Spazio tra i pulsanti nei dispositivi piccoli */
  }

  .modal-footer .btn {
    margin: 10px 0;
  }
}
.modal {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.modal-dialog {
    margin: auto;
}
/* Container */
.container {
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;
}

/* Table Styling */
.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
}

.table th,
.table td {
    padding: 1.5rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    text-align: center;
}

.table img {
    width: 100px;
    height: auto;
}

/* Adjusting Button Spacing */
.table .btn {
    margin-bottom: 0.5rem;
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

/* Media Queries */
@media (max-width: 768px) {
    .table {
        display: block;
        width: 100%;
        overflow-x: auto;
    }

    .sidebar {
        display: none; /* Nasconde la sidebar per schermi piccoli */
    }

    .content {
        margin-left: 0;
        padding: 10px;
        width: 100%;
    }

    .container {
        padding: 0;
    }
}
/* Global Styles */
body {
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
}

/* Sidebar */
.sidebar {
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  background-color: #343a40;
  overflow-x: hidden;
  transition: width 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  position: fixed;
  top: 56px;
  left: 0;
  z-index: 999;
  padding-top: 20px;
  width: 240px; /* Aumenta la larghezza della sidebar */
}

.sidebar .nav-link {
  color: #ffffff;
  font-weight: bold;
  padding: 12px 24px;
  text-align: left;
  font-size: 1rem;
  border-radius: 5px;
  transition: background-color 0.2s ease;
}

.sidebar .nav-link:hover {
  background-color: #495057;
  color: #f8f9fa;
}

.btn-logout {
  width: 80%;
  margin-left: 10%;
  border-radius: 5px;
  padding: 12px 0;
  font-size: 1rem;
  position: absolute;
  bottom: 20px;
  left: 0;
}

/* Content */
.content {
  transition: margin-left 0.3s ease;
  min-height: 100vh;
  margin-left: 240px; /* Aumenta il margine per adattarsi alla larghezza della sidebar */
  padding-top: 70px;
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    position: absolute;
  }
  .content {
    margin-left: 0;
    width: 100%;
  }
}

/* Header */
.header {
  background-color: #343a40;
  color: white;
  padding: 10px 20px;
  font-size: 1.25rem;
  font-weight: bold;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1050;
  display: flex;
  align-items: center;
  height: 56px;
}

/* Buttons */
.btn-primary, .btn-danger {
  padding: 0.5rem 1rem;
  margin: 0.2rem;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #004085;
}

.btn-danger:hover {
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-sm {
  padding: 0.5rem 1rem;
}

button.btn.btn-sm.btn-light {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-top: auto;
  margin-bottom: auto;
}

/* Responsive Table */
.table {
  width: 100%;
  overflow-x: auto;
}

.table-responsive {
  width: 100%;
  margin-bottom: 1rem;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 768px) {
  .table {
    display: block;
    width: 100%;
    overflow-x: auto;
  }

  .table thead {
    display: none;
  }
  
  .table tbody tr {
    display: block;
    margin-bottom: 0.625em;
  }
  
  .table td {
    display: block;
    text-align: right;
    font-size: 0.8em;
    border-bottom: 1px solid #ddd;
    position: relative;
    padding-left: 50%;
  }

  .table td:before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-weight: bold;
    text-align: left;
  }
}

/* Modal */
.modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  z-index: 1050;
  margin: 0;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.modal-content {
  padding: 20px;
  border-radius: 10px;
  width: auto;
  margin: auto;
}

.modal-header {
  border-bottom: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header .close {
  font-size: 1.5rem;
  background: none;
  border: none;
  padding: 0;
  margin-left: auto;
  cursor: pointer;
}

.modal-footer .btn {
  margin: 0 10px; /* Margine tra i pulsanti */
}

.modal-backdrop.show {
  opacity: 0.5;
}

@media (max-width: 768px) {
  .modal-dialog {
    width: 90%;
    margin: 0;
  }

  .modal-content {
    width: 100%;
    margin: 10px;
  }

  .btn {
    display: block;
    width: 100%;
    margin-bottom: 10px; /* Spazio tra i pulsanti nei dispositivi piccoli */
  }

  .modal-footer .btn {
    margin: 10px 0;
  }
}
.dashboard-overview {
  padding: 20px;
}

.dashboard-overview h2 {
  margin-bottom: 20px;
  text-align: center;
}

.dashboard-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card {
  background-color: #007bff;
  color: #fff;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  flex: 1 1 calc(25% - 20px);
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.card:hover {
  transform: translateY(-5px);
}

.card h3 {
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.card p {
  font-size: 2rem;
  font-weight: bold;
}

@media (max-width: 768px) {
  .dashboard-cards {
    flex-direction: column;
  }

  .card {
    flex: 1 1 100%;
    margin: 10px 0;
  }
}
/* Global Styles */
body, html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
}

/* Sidebar */
.sidebar {
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  background-color: #343a40;
  overflow-x: hidden;
  transition: width 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  position: fixed;
  top: 56px;
  left: 0;
  z-index: 999;
  padding-top: 20px;
}

.sidebar .nav-link {
  color: #ffffff;
  font-weight: bold;
  padding: 12px 24px;
  text-align: left;
  font-size: 1rem;
  border-radius: 5px;
  transition: background-color 0.2s ease;
}

.sidebar .nav-link:hover {
  background-color: #495057;
  color: #f8f9fa;
}

.btn-logout {
  width: 80%;
  margin-left: 10%;
  border-radius: 5px;
  padding: 12px 0;
  font-size: 1rem;
  position: absolute;
  bottom: 20px;
  left: 0;
}

/* Content */
.content {
  transition: margin-left 0.3s ease;
  min-height: 100vh;
  margin-left: 240px;
  padding: 20px;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    position: absolute;
  }
  .content {
    margin-left: 0;
    width: 100%;
  }
}

/* Header */
.header {
  background-color: #343a40;
  color: white;
  padding: 10px 20px;
  font-size: 1.25rem;
  font-weight: bold;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1050;
  display: flex;
  align-items: center;
  height: 56px;
}

/* Dashboard Cards */
.dashboard-cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.card {
  background-color: #007bff;
  color: white;
  border-radius: 8px;
  padding: 20px;
  flex: 1;
  min-width: 200px;
  text-align: center;
}

@media (max-width: 768px) {
  .card {
    width: 100%;
  }
}
/* Global Styles */
body {
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
}

/* Sidebar */
.sidebar {
  background-color: #343a40;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 240px;
  transition: all 0.3s ease;
}

.sidebar.collapsed {
  width: 60px;
}

.sidebar .nav-link {
  color: #ffffff;
  padding: 12px;
  display: block;
  text-align: left;
  font-size: 1rem;
}

.sidebar .nav-link:hover {
  background-color: #495057;
}

.btn-logout {
  width: 100%;
  padding: 10px;
  margin: 20px 0;
}

/* Content */
.content {
  margin-left: 240px;
  padding: 20px;
  transition: margin-left 0.3s ease;
}

.sidebar.collapsed + .content {
  margin-left: 60px;
}

@media (max-width: 768px) {
  .sidebar {
    width: 60px;
  }

  .content {
    margin-left: 60px;
  }

  .dashboard-cards {
    flex-direction: column;
  }

  .card {
    margin-bottom: 20px;
    width: 100%;
  }
}

/* Header */
.header {
  background-color: #343a40;
  color: white;
  padding: 10px;
  text-align: center;
  font-size: 1.25rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}
/* Sidebar */
.sidebar {
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  background-color: #343a40;
  height: 100vh;
  overflow-x: hidden;
  position: fixed;
  top: 56px;
  left: 0;
  z-index: 999;
  padding-top: 20px;
  transition: width 0.3s ease;
}

.sidebar .nav-link {
  color: #ffffff;
  font-weight: bold;
  padding: 12px 24px;
  text-align: left;
  font-size: 1rem;
  border-radius: 5px;
  transition: background-color 0.2s ease;
}

.sidebar .nav-link:hover {
  background-color: #495057;
  color: #f8f9fa;
}

.btn-logout {
  width: 80%;
  margin-left: 10%;
  border-radius: 5px;
  padding: 12px 0;
  font-size: 1rem;
  position: absolute;
  bottom: 20px;
  left: 0;
}

/* Content */
.content {
  transition: margin-left 0.3s ease;
  min-height: 100vh;
  overflow-x: auto;
  margin-left: 240px; /* Modifica la larghezza della sidebar */
  padding-top: 70px;
}

@media (max-width: 768px) {
  .sidebar {
    width: 60px; /* Riduce la larghezza della sidebar su dispositivi mobili */
  }
  .content {
    margin-left: 60px; /* Allinea il contenuto con la sidebar ridotta */
  }
}

/* Dashboard Cards */
.dashboard-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
}

.dashboard-cards .card {
  flex: 1 1 calc(25% - 10px); /* 4 cards per row, 10px spacing */
  background-color: #007bff;
  color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .dashboard-cards .card {
    flex: 1 1 100%; /* Full width on small screens */
  }
}

/* Dashboard Cards */
.dashboard-cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 70px;
}

.card {
  background-color: #007bff;
  color: white;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  flex: 1 1 200px;
}

.card h3 {
  font-size: 1.25rem;
}

.card p {
  font-size: 2rem;
  margin: 0;
}

/* Tables */
.table-responsive {
  overflow-x: auto;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th,
.table td {
  padding: 1rem;
  text-align: center;
}

.table img {
  width: 50px;
  height: auto;
}

@media (max-width: 768px) {
  .table th,
  .table td {
    padding: 0.5rem;
  }
}

/* Modal */
.modal-dialog {
  max-width: 500px;
  margin: 1.75rem auto;
}

.modal-content {
  padding: 20px;
  border-radius: 10px;
}

/* Utility classes */
.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-grow-1 {
  flex-grow: 1;
}

.p-4 {
  padding: 1.5rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

@media (min-width: 992px) {
  .content {
    margin-left: 240px;
  }

  .sidebar {
    width: 240px;
  }
}





